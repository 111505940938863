import Link from "next/link";

const LinkComponent = ({ href, text }) => {
  return (
    <Link href={href}>
      <a className="text-[#004daa] underline">{text}</a>
    </Link>
  );
};

export default LinkComponent;
