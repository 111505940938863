const links = [
  "#tarsbot",
  "https://news.ycombinator.com/#tarsbot",
  "https://startgid.hellotars.com/conv/SJ50Gu/#tarsbot",
  "https://startgid.hellotars.com/conv/SJ50Gu/?_startgid=2#tarsbot",
  "https://memegen.hellotars.com/conv/r1d0p0/#tarsbot",
  "https://quick3gif.hellotars.com/conv/SyQdGH/",
];

export { links };
