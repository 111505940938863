import Head from "next/head";
import Script from "next/script";
import { Fragment } from "react";
import LinkComponent from "../components/linkComponent";
import { links } from "../components/linkComponent/data";

const Index = () => {
  return (
    <>
      <Head>
        <title>TARS</title>
      </Head>
      <Script
        type="text/javascript"
        id="tars-widget"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: process.env.convbotJS,
        }}
      />
      <main className="p-12">
        <h1 className="text-[2em] my-[18.76px] font-bold">TARS</h1>
        <p className="my-[14px]">Welcome to TARS 3000</p>
        {links.map((val, idx) => (
          <Fragment key={idx}>
            <LinkComponent href={val} text={`Open Tars Bot ${idx + 1}`} />
            {idx % 2 == 0 ? (
              <br />
            ) : (
              <>
                <br />
                <br />
              </>
            )}
          </Fragment>
        ))}
      </main>
    </>
  );
};

export default Index;
